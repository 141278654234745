'use client'

import { Component } from 'react'
import { Cell, Icon, Spacer, Text } from '@vinted/web-ui'
import { Globe24 } from '@vinted/monochrome-icons'

import { FormattedMessage } from 'components/@support'
import { EventTrackerProps, withTracking } from 'containers/TrackingProvider'
import { clickEvent, clickEventArgs } from 'libs/common/event-tracker/events'

export enum Status {
  Original = 'original',
  Translating = 'translating',
  Translated = 'translated',
}

type Props = EventTrackerProps & {
  onClick: (isTranslated: boolean) => void | Promise<void>
  isTranslated: boolean
  autotranslate: boolean
  tracking?: clickEventArgs
  renderButton?: (status: Status) => JSX.Element
  isNavigating?: boolean
  clickable?: boolean
}

type State = {
  status: Status
  isTracked: boolean
}

class TranslationControl extends Component<Props, State> {
  static defaultProps = {
    isTranslated: false,
    isNavigating: true,
  }

  state: State = {
    status: this.props.isTranslated ? Status.Translated : Status.Original,
    isTracked: false,
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (prevState.status === Status.Translating) return null

    return { status: nextProps.isTranslated ? Status.Translated : Status.Original }
  }

  componentDidMount() {
    if (!this.props.isTranslated && this.props.autotranslate) {
      this.toggleTranslation()
    }
  }

  // TODO: convert into functional component
  // eslint-disable-next-line class-methods-use-this
  translateById = (id: string) => <FormattedMessage id={`translation.status.${id}`} />

  toggleTranslation = async () => {
    const { onClick, isTranslated } = this.props
    const { status } = this.state

    if (status === Status.Translating) return

    const prevStatus = status
    this.setState({ status: Status.Translating })

    await onClick(isTranslated)

    this.setState({ status: prevStatus === Status.Original ? Status.Translated : Status.Original })
  }

  trackButtonClick = () => {
    const { tracking, track } = this.props

    if (!tracking) return

    const event = clickEvent(tracking)
    track(event)
  }

  handleClick = () => {
    if (!this.state.isTracked) {
      this.trackButtonClick()
      this.setState({ isTracked: true })
    }
    this.toggleTranslation()
  }

  renderButton = () => {
    const { status } = this.state

    const messageTheme = status === Status.Translating ? undefined : 'primary'

    return (
      <div className="u-flexbox u-align-items-center">
        <Icon name={Globe24} color={Icon.Color.Primary} aria={{ 'aria-hidden': 'true' }} />
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
        <div>
          {status === Status.Translated && (
            <>
              <Text text={this.translateById('translated_prefix')} />
              <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
              <span>‧</span>
              <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
            </>
          )}
          <Text text={this.translateById(status)} theme={messageTheme} />
        </div>
      </div>
    )
  }

  render() {
    const { renderButton, isNavigating, clickable } = this.props
    const { status } = this.state

    return (
      <Cell
        styling={Cell.Styling.Tight}
        onClick={this.handleClick}
        type={isNavigating ? Cell.Type.Navigating : undefined}
        role="button"
        clickable={clickable}
      >
        {renderButton ? renderButton(status) : this.renderButton()}
      </Cell>
    )
  }
}

export default withTracking(TranslationControl)
