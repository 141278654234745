import { getLocalStorageItem, setLocalStorageItem } from 'libs/utils/localStorage'

import { SELLER_INSIGHTS_NOTIFICATIONS_KEY } from '../constants'

export default function useIsSellerInsightsNotificationsEnabled() {
  const isSellerInsightsNotificationsEnabled = getLocalStorageItem(
    SELLER_INSIGHTS_NOTIFICATIONS_KEY,
  )

  if (!isSellerInsightsNotificationsEnabled) {
    setLocalStorageItem(SELLER_INSIGHTS_NOTIFICATIONS_KEY, 'false')
  }

  const isEnabled = isSellerInsightsNotificationsEnabled === 'true'

  return isEnabled
}
