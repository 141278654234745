'use client'

import { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Dialog, Text, Image } from '@vinted/web-ui'

import { abTestExposeEvent, clickEvent } from 'libs/common/event-tracker/events'
import { formatCurrencyAmount } from 'libs/utils/formatString'
import { navigateToPage } from 'libs/utils/window'

import { getItem, getTip, getUploadAnotherItemTip } from 'data/api'
import { getItemThumbnail } from 'data/utils/item'
import { getLocale } from 'state/intl/selectors'
import { getUserId } from 'state/session/selectors'
import { nextPromoAfterItemUpload } from 'state/profile/actions'
import { getShowUploadAnotherItemTip, getUploadedItemId } from 'state/profile/selectors'
import { WITHOUT_BRAND_ID } from 'state/item-upload/constants'
import useAsset from 'hooks/useAsset'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import { ITEM_UPLOAD_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ItemThumbnailSize } from 'constants/images'
import { transformCurrencyAmountDto } from 'data/transformers/currency-amount'

import ItemBox from 'components/ItemBox'
import { UserTipResp } from 'types/api'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { ItemBoxDto } from 'types/dtos'
import useFeatureSwitch from 'hooks/useFeatureSwitch'

type PartialItemBoxProps = Pick<ComponentProps<typeof ItemBox>, 'price' | 'image'> & {
  brand: string | null
  size: string | null
  itemBox?: ItemBoxDto | null
}

const ListPromotionModal = () => {
  const translate = useTranslate()
  const { track } = useTracking()
  const dispatch = useDispatch()
  const locale = useSelector(getLocale)
  const userId = useSelector(getUserId)
  const newItemId = useSelector(getUploadedItemId)
  const asset = useAsset('/assets/list-promotion')
  const showUploadAnotherItemTip = useSelector(getShowUploadAnotherItemTip)
  const isUploadAnotherItemTipEnabled = useSelector(
    getIsFeatureSwitchEnabled('upload_another_item_tip_refactor_web'),
  )
  const isItemBoxDescriptionChangesEnabled = useFeatureSwitch('item_box_description_changes_web')

  const [item, setItem] = useState<PartialItemBoxProps>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [tipBody, setTipBody] = useState('')
  const [tipTitle, setTipTitle] = useState('')

  const trackExposeEvent = useCallback(
    (response: UserTipResp) => {
      if (!response.ab_test?.variant) return

      const { country_code, test_anon_id, test_name, test_user_id, variant, test_id } =
        response.ab_test

      track(
        abTestExposeEvent({
          id: test_id,
          name: test_name,
          variant,
          anonId: test_anon_id,
          userId: (test_user_id && Number(test_user_id)) || null,
          countryCode: country_code,
        }),
      )
    },
    [track],
  )

  const fetchItem = useCallback(
    async (itemId: string | number) => {
      const response = await getItem({ id: itemId })

      if ('errors' in response) {
        dispatch(nextPromoAfterItemUpload())

        return
      }

      if (!response.item) {
        dispatch(nextPromoAfterItemUpload())

        return
      }

      setItem({
        brand:
          response.item.brand_dto?.id === WITHOUT_BRAND_ID
            ? ''
            : response.item.brand_dto?.title || null,
        size: response.item.size_title,
        price: formatCurrencyAmount(transformCurrencyAmountDto(response.item.price), locale),
        image: getItemThumbnail(response.item.photos, ItemThumbnailSize.Large),
        itemBox: response.item.item_box,
      })
    },
    [dispatch, locale],
  )

  const fetchTip = useCallback(async () => {
    if (!userId) {
      dispatch(nextPromoAfterItemUpload())

      return
    }

    const response = await getTip(userId, 'upload_more')

    if ('errors' in response) {
      dispatch(nextPromoAfterItemUpload())

      return
    }

    trackExposeEvent(response)

    if (response.tip) {
      setTipBody(response.tip.body)
      setTipTitle(response.tip.title)
      setIsModalOpen(true)
    } else {
      dispatch(nextPromoAfterItemUpload())
    }
  }, [dispatch, trackExposeEvent, userId])

  const fetchUploadAnotherItemTip = useCallback(
    async (itemId: number) => {
      if (!showUploadAnotherItemTip) {
        dispatch(nextPromoAfterItemUpload())

        return
      }

      const response = await getUploadAnotherItemTip(itemId)

      if ('errors' in response) {
        dispatch(nextPromoAfterItemUpload())

        return
      }

      setTipBody(response.body)
      setTipTitle(response.title)
      setIsModalOpen(true)
      setItem({
        ...response.item,
        price: formatCurrencyAmount(transformCurrencyAmountDto(response.item.price), locale),
        image: getItemThumbnail([response.item.photo], ItemThumbnailSize.Large),
      })
    },
    [dispatch, showUploadAnotherItemTip, locale],
  )

  useEffect(() => {
    if (!newItemId) {
      dispatch(nextPromoAfterItemUpload())

      return
    }

    if (isUploadAnotherItemTipEnabled) {
      fetchUploadAnotherItemTip(newItemId)

      return
    }

    fetchTip()
    fetchItem(newItemId)
  }, [
    dispatch,
    fetchTip,
    fetchItem,
    newItemId,
    isUploadAnotherItemTipEnabled,
    fetchUploadAnotherItemTip,
  ])

  const trackClickEvent = (target: ClickableElement) => {
    track(clickEvent({ target }))
  }

  const onAcceptButtonClick = () => {
    trackClickEvent(ClickableElement.UploadMoreAccept)
    navigateToPage(ITEM_UPLOAD_URL)
  }

  const onSkipButtonClick = () => {
    trackClickEvent(ClickableElement.UploadMoreCancel)
    setIsModalOpen(false)
    dispatch(nextPromoAfterItemUpload())
  }

  const renderItemBox = () => {
    if (!item) return null

    const description =
      isItemBoxDescriptionChangesEnabled && item.itemBox
        ? {
            title: item.itemBox.first_line,
            subtitle: item.itemBox.second_line,
            exposure: item.itemBox.exposure,
          }
        : {
            title: item.brand,
            subtitle: item.size,
          }

    return (
      <div className="u-position-relative">
        <Image src={asset('background.jpg')} />
        <div className="list-promotion__item-wrapper">
          <Card styling={Card.Styling.Elevated}>
            <div className="list-promotion__item-box">
              <ItemBox
                image={item.image}
                price={item.price}
                url=""
                description={description}
                favourite={{ count: 0, favourited: false }}
              />
              <div className="list-promotion__overlay" />
            </div>
          </Card>
        </div>
      </div>
    )
  }

  return (
    <Dialog show={!!item && isModalOpen}>
      <div className="list-promotion">
        {renderItemBox()}
        <div className="c-modal__inline-content">
          <div className="c-modal__title">
            <Text text={tipTitle} type={Text.Type.Heading} />
          </div>
          <div className="c-modal__body">
            <Text text={tipBody} />
          </div>
        </div>
        <div className="c-modal__actions">
          <div className="c-modal__action">
            <Button
              text={translate('list_promotion.submit_cta')}
              onClick={onAcceptButtonClick}
              styling={Button.Styling.Filled}
              size={Button.Size.Medium}
            />
          </div>
          <div className="c-modal__action">
            <Button
              text={translate('list_promotion.cancel_cta')}
              onClick={onSkipButtonClick}
              styling={Button.Styling.Flat}
              size={Button.Size.Medium}
            />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ListPromotionModal
