'use client'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spacer } from '@vinted/web-ui'
import classNames from 'classnames'

import PortalMergeFeedBanner from 'components/PortalMergeFeedBanner'
import SeparatedList from 'components/SeparatedList'
import BadgeBanner from 'pages/Profile/Badges/BadgeBanner/BadgeBanner'
import { ClosetPromotionCheckout } from 'components/ClosetPromotion'
import { exposeVasBannerLock } from 'data/api'

import useBreakpoint from 'hooks/useBreakpoint'
import useAbTest from 'hooks/useAbTest'

import { Screen } from 'constants/tracking/screens'
import { VasEntryPoint } from 'constants/vas-entry-point'

import * as statelessActions from 'state/banners/actions'
import * as selectors from 'state/banners/selectors'

import ReferralsBanner from '../ReferralsBanner'
import ClosetPromotionBanner from './ClosetPromotionBanner'
import CollectionsBanner from './CollectionsBanner'
import BumpBanner from './BumpBanner'
import ProfileTaxpayerBanner from './ProfileTaxpayerBanner'
import useVasEntryPointsContext from './VasEntryPointsProvider/useVasEntryPointsContext'

type Props = {
  userId: number
  renderCountDownBanner: () => JSX.Element | null
}

const ProfileOwnerBanners = ({ userId, renderCountDownBanner }: Props) => {
  const dispatch = useDispatch()
  const breakpoints = useBreakpoint()
  const banners = useSelector(selectors.getBanners)
  const vasBannerLockAbTest = useAbTest({ abTestName: 'vas_banner_lock' })

  const { vasEntryPoints } = useVasEntryPointsContext()

  const [isPrecheckoutOpen, setIsPrecheckoutOpen] = useState(false)

  function handlePrecheckoutOpen() {
    setIsPrecheckoutOpen(true)
  }

  function handlePrecheckoutClose() {
    setIsPrecheckoutOpen(false)
  }

  useEffect(() => {
    if (!vasBannerLockAbTest?.variant) return
    if (!vasEntryPoints) return

    exposeVasBannerLock()
  }, [vasBannerLockAbTest, vasEntryPoints])

  useEffect(() => {
    dispatch(statelessActions.fetchBannersRequest())
  }, [dispatch])

  function renderSeparator() {
    return breakpoints.phones ? (
      <Spacer orientation={Spacer.Orientation.Horizontal} size={Spacer.Size.Medium} />
    ) : (
      <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.XLarge} />
    )
  }

  function renderPortalMergeBanner() {
    const { portalMergeFeed } = banners

    if (!portalMergeFeed) return null

    return <PortalMergeFeedBanner banner={portalMergeFeed} screen={Screen.CurrentUserProfile} />
  }

  const isPromotedClosetsCheckoutEntryPointAvailable = vasEntryPoints?.some(
    entryPoint => entryPoint.name === VasEntryPoint.ProfilePromotedClosets,
  )

  const promotedClosetsBanner = vasEntryPoints?.find(
    entryPoint =>
      entryPoint.name === VasEntryPoint.ProfilePromotedClosets ||
      entryPoint.name === VasEntryPoint.PromotedClosetsStats,
  )

  const collectionsBanner = vasEntryPoints?.find(
    entryPoint => entryPoint.name === VasEntryPoint.Collections,
  )

  const bumpBanner = vasEntryPoints?.find(entryPoint => entryPoint.name === VasEntryPoint.PushUps)

  const classes = classNames({ 'u-flexbox': breakpoints.tabletsUp })
  const showInlineBanners = bumpBanner || promotedClosetsBanner || banners.portalMergeFeed

  return (
    <>
      <ProfileTaxpayerBanner taxpayerBanner={banners.taxpayerBanner} />
      <ReferralsBanner
        bottomSpacer={breakpoints.tabletsUp && <Spacer size={Spacer.Size.XLarge} />}
      />
      {renderCountDownBanner()}
      <SeparatedList
        separatorAtBreakpoint={{
          phones: <Spacer size={Spacer.Size.Medium} />,
          tablets: <Spacer size={Spacer.Size.Large} />,
        }}
      >
        {showInlineBanners && (
          <div className={classes}>
            <SeparatedList separator={renderSeparator()}>
              {bumpBanner && <BumpBanner bumpBanner={bumpBanner} userId={userId} />}
              {promotedClosetsBanner && (
                <ClosetPromotionBanner
                  promotedClosetsBanner={promotedClosetsBanner}
                  handlePrecheckoutOpen={handlePrecheckoutOpen}
                />
              )}
              {breakpoints.desktops && collectionsBanner && (
                <CollectionsBanner collectionsBanner={collectionsBanner} />
              )}
              {renderPortalMergeBanner()}
            </SeparatedList>
          </div>
        )}
        {!breakpoints.desktops && collectionsBanner && (
          <CollectionsBanner collectionsBanner={collectionsBanner} />
        )}
      </SeparatedList>
      <BadgeBanner />
      {isPromotedClosetsCheckoutEntryPointAvailable && (
        <ClosetPromotionCheckout
          isOpen={isPrecheckoutOpen}
          handlePrecheckoutClose={handlePrecheckoutClose}
        />
      )}
    </>
  )
}

export default ProfileOwnerBanners
