'use client'

import { useSelector } from 'react-redux'
import { Button, Container, Dialog, Spacer, Text } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

import { deleteBusinessRegistration } from 'data/api'
import { reloadPage } from 'libs/utils/window'
import * as selectors from 'state/session/selectors'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const CancelRegistrationModal = ({ isOpen, onClose }: Props) => {
  const translate = useTranslate('business.account.cancel_registration_modal')
  const businessAccountId = useSelector(selectors.getBusinessAccountId)

  async function handleCancelRegistrationClick() {
    if (!businessAccountId) return

    await deleteBusinessRegistration(businessAccountId)

    reloadPage()
  }

  return (
    <Dialog show={isOpen}>
      <Container>
        <Text
          text={translate('title')}
          type={Text.Type.Heading}
          alignment={Text.Alignment.Center}
          width={Text.Width.Parent}
        />
        <Spacer />
        <Text text={translate('body')} />
        <Spacer size={Spacer.Size.XLarge} />
        <Button
          text={translate('actions.cancel')}
          onClick={handleCancelRegistrationClick}
          styling={Button.Styling.Filled}
        />
        <Spacer size={Spacer.Size.Large} />
        <Button text={translate('actions.close')} onClick={onClose} styling={Button.Styling.Flat} />
      </Container>
    </Dialog>
  )
}

export default CancelRegistrationModal
