'use client'

import { useState } from 'react'

import { Button, Cell, Divider, Dialog, Navigation, Spacer, Toggle } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import { setLocalStorageItem } from 'libs/utils/localStorage'
import { clickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import SeparatedList from 'components/SeparatedList'

import useIsSellerInsightsNotificationsEnabled from '../hooks/useIsSellerInsightsNotificationsEnabled'
import { SELLER_INSIGHTS_NOTIFICATIONS_KEY } from '../constants'

type Props = {
  show: boolean
  onCloseOrConfirm: () => void
  period?: string
}

type ContentRow = {
  title: string
  explanation: string
}

const SellerInsightsValuePropositionModal = ({ show, onCloseOrConfirm, period }: Props) => {
  const translate = useTranslate('profile.seller_insights.modal')
  const translateRows = useTranslate('profile.seller_insights.modal.body.blocks')
  const { track } = useTracking()
  const isSellerInsightsNotificationsEnabled = useIsSellerInsightsNotificationsEnabled()
  const [isSellerInsightsToggleChecked, setIsSellerInsightsToggleChecked] = useState(
    isSellerInsightsNotificationsEnabled,
  )

  const contentRows: Array<ContentRow> = [
    {
      title: translateRows('wardrobe_value.title'),
      explanation: translateRows('wardrobe_value.explanation'),
    },
    { title: translateRows('earnings.title'), explanation: translateRows('earnings.explanation') },
    ...(period
      ? [
          {
            title: translateRows('recent.title'),
            explanation: translateRows('recent.explanation', { period }),
          },
        ]
      : []),
  ]

  const handleOnClose = () => {
    onCloseOrConfirm()
  }

  const handleToggle = () => {
    setIsSellerInsightsToggleChecked(prevState => {
      const isChecked = !prevState
      setLocalStorageItem(SELLER_INSIGHTS_NOTIFICATIONS_KEY, JSON.stringify(isChecked))
      track(
        clickEvent({
          target: ClickableElement.SellerInsightsToggle,
          screen: Screen.CurrentUserProfile,
          targetDetails: isChecked ? 'on' : 'off',
        }),
      )

      return isChecked
    })
  }
  const renderModalBody = () => {
    return (
      <div className="u-fill-width">
        <Navigation
          body={translate('title')}
          right={
            <Button
              inverse
              inline
              iconName={X24}
              onClick={handleOnClose}
              aria={{
                'aria-label': translate('a11y.actions.close'),
              }}
            />
          }
        />
        <Divider />

        <Cell>
          <Cell
            highlighted
            title={translate('body.notifications_toggle.title')}
            body={translate('body.notifications_toggle.subtitle')}
            suffix={
              <Toggle
                aria={{
                  'aria-label': translate('body.notifications_toggle.title'),
                }}
                checked={isSellerInsightsToggleChecked}
                onClick={handleToggle}
                name="seller_insights_notifications"
                testId="seller_insights_notifications"
              />
            }
          />
          <Spacer size={Spacer.Size.Large} />
          <SeparatedList separator={<Spacer size={Spacer.Size.Large} />}>
            {contentRows.map(({ title, explanation }) => (
              <Cell
                key={`seller-insights-modal-bullet-${title}`}
                title={title}
                body={explanation}
                styling={Cell.Styling.Tight}
              />
            ))}
          </SeparatedList>
          <Spacer size={Spacer.Size.XLarge} />
          <Button
            text={translate('body.actions.confirm')}
            styling={Button.Styling.Filled}
            size={Button.Size.Medium}
            onClick={onCloseOrConfirm}
            testId="seller-insights-modal-confirm-button"
          />
        </Cell>
      </div>
    )
  }

  return (
    <Dialog
      hasScrollableContent
      defaultCallback={handleOnClose}
      show={show}
      testId="seller-insights-value-proposition-modal"
    >
      {renderModalBody()}
    </Dialog>
  )
}

export default SellerInsightsValuePropositionModal
