'use client'

import { useEffect } from 'react'

import useTracking from 'hooks/useTracking'
import useFetch from 'hooks/useFetch'
import useStartBumpCheckout from 'hooks/useStartBumpCheckout'

import { clickEvent } from 'libs/common/event-tracker/events'
import { navigateToPage } from 'libs/utils/window'
import { getBumpableItemDataFromModel } from 'data/utils/bump'

import { getUserItems } from 'data/api'
import { transformUserItemsResponse } from 'data/api/transformers/response'

import { ItemFilterApiParam } from 'constants/item-filter'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { BUMP_MULTIPLE_ITEM_SELECTION_URL } from 'constants/routes'
import { VasImages } from 'constants/vas-entry-point'

import { VasEntryPointModel } from 'types/models'

import VasBanner from '../../VasBanner'

type Props = {
  bumpBanner?: VasEntryPointModel
  userId: number
}

const PER_PAGE = 2
const DEFAULT_PAGE = 0

const BumpBanner = ({ bumpBanner, userId }: Props) => {
  const { track } = useTracking()
  const { startCheckout } = useStartBumpCheckout()

  const { fetch: fetchUserItems, transformedData: items } = useFetch(
    getUserItems,
    transformUserItemsResponse,
  )

  useEffect(() => {
    if (!bumpBanner) return
    fetchUserItems({
      userId,
      filter: ItemFilterApiParam.Pushable,
      perPage: PER_PAGE,
      currentPage: DEFAULT_PAGE,
    })
  }, [fetchUserItems, userId, bumpBanner])

  if (!bumpBanner) return null

  function onClickBumpBanner() {
    if (!items) return
    startCheckout(getBumpableItemDataFromModel(items[0]!))
  }

  const handleBumpBannerClick = () => {
    track(
      clickEvent({
        screen: Screen.CurrentUserProfile,
        target: ClickableElement.MultiplePushUp,
      }),
    )
    if (items?.length === 1) {
      onClickBumpBanner()

      return
    }
    navigateToPage(BUMP_MULTIPLE_ITEM_SELECTION_URL)
  }

  const bannerProps = {
    ...bumpBanner,
    onClick: handleBumpBannerClick,
    testId: 'bump-banner',
    icon: VasImages.VasBump,
  }

  return <VasBanner {...bannerProps} />
}

export default BumpBanner
