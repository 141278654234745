'use client'

import { useEffect } from 'react'
import { Button, Cell, Container, Divider, Dialog, Navigation, Text, Spacer } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import useBreakpoint from 'hooks/useBreakpoint'
import useFetch from 'hooks/useFetch'
import useTracking from 'hooks/useTracking'

import ScrollableArea from 'components/ScrollableArea'
import { ProductItem } from 'components/ProductItem'
import ItemGrid from 'components/ItemGrid'
import ContentLoader from 'components/ContentLoader'
import ErrorState from 'components/ErrorState'

import { GridItemRenderProps } from 'types/components'
import { ProductItemModel } from 'types/models'
import { getCollection } from 'data/api'
import { transformCollectionResponse } from 'data/api/transformers/response'
import { reloadPage } from 'libs/utils/window'
import { clickEvent, favouriteItemEvent } from 'libs/common/event-tracker/events'
import { ContentSource } from 'constants/tracking/content-sources'
import { ClickableElement } from 'constants/tracking/clickable-elements'

type Props = {
  isShown: boolean
  viewingSelf: boolean
  collectionId: number
  onClose: () => void
}

const PHONES_MODAL_SIZE = { width: '100%', height: '100%' }
const TABLETSUP_MODAL_SIZE = {
  width: 'calc(100% - 32px)',
  height: 'calc(100% - 80px)',
  maxWidth: '1240px',
}

const CollectionViewModal = ({ isShown, viewingSelf, collectionId, onClose }: Props) => {
  const {
    fetch: fetchCollection,
    transformedData: collectionData,
    isLoading,
    error,
  } = useFetch(getCollection, transformCollectionResponse)
  const { track } = useTracking()

  const translate = useTranslate('featured_collection.multiple.view_modal')
  const breakpoints = useBreakpoint()

  const contentDimensions = breakpoints.tabletsUp ? TABLETSUP_MODAL_SIZE : PHONES_MODAL_SIZE

  useEffect(() => {
    fetchCollection({ id: collectionId })
  }, [fetchCollection, collectionId])

  function renderNavigation() {
    return (
      <Navigation
        right={
          <Button
            type={Button.Type.Button}
            iconName={X24}
            theme="amplified"
            styling={Button.Styling.Flat}
            inline
            testId="collection-view-modal-close-button"
            onClick={onClose}
            aria={{
              'aria-label': translate('a11y.actions.close'),
            }}
          />
        }
        body={
          <Text
            type={Text.Type.Title}
            width={Text.Width.Parent}
            alignment={Text.Alignment.Center}
            text={translate('title')}
          />
        }
      />
    )
  }

  function resolveItemWidth() {
    return 'one-fifth'
  }

  function handleFavouriteToggle({
    itemId,
    isFollowEvent,
  }: {
    itemId: number
    isFollowEvent: boolean
  }) {
    track(clickEvent({ target: ClickableElement.Favourite }))
    track(
      favouriteItemEvent({
        itemId,
        isFollowEvent,
        contentSource: ContentSource.FeaturedCollection,
      }),
    )
  }

  const renderItem = (props: GridItemRenderProps<ProductItemModel>) => {
    const {
      item: { data },
    } = props

    return (
      <ProductItem
        showOwner={false}
        item={data}
        viewingSelf={viewingSelf}
        testId={`collection-item-${data.id}`}
        showStatus
        onFavouriteToggle={handleFavouriteToggle}
        isBumpTextHidden
      />
    )
  }

  function renderContent() {
    if (error) return <ErrorState onCtaClick={reloadPage} />

    if (isLoading || !collectionData) {
      return (
        <ContentLoader
          styling={ContentLoader.Styling.Wide}
          size={ContentLoader.Size.Large}
          testId="view-modal-loader"
        />
      )
    }

    const gridItems = collectionData.items.map(item => ({ id: item.id, data: item }))

    return (
      <div className="regular-container">
        <Spacer />
        <Cell>
          <Text
            type={breakpoints.tabletsUp ? Text.Type.Heading : Text.Type.Title}
            text={collectionData.title}
          />
        </Cell>
        <Spacer />
        <Container styling={Container.Styling.Narrow}>
          <ItemGrid items={gridItems} renderItem={renderItem} widthResolver={resolveItemWidth} />
        </Container>
      </div>
    )
  }

  return (
    <Dialog
      show={isShown}
      hasScrollableContent
      contentDimensions={contentDimensions}
      data-testid="collection-view-modal"
    >
      <div className="u-fill-width">
        {renderNavigation()}
        <Divider />
        <ScrollableArea>{renderContent()}</ScrollableArea>
      </div>
    </Dialog>
  )
}

export default CollectionViewModal
