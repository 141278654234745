import { useEffect } from 'react'

import useFetch from 'hooks/useFetch'

import { getSellerFilters } from 'data/api'
import { transformGetSellerFiltersResponse } from 'data/api/transformers/response'
import useAbTest from 'hooks/useAbTest'

const useGetSellerFilters = () => {
  const redundantSellerFiltersAbTest = useAbTest({
    abTestName: 'remove_redundant_wardrobe_filters',
  })

  const isRedundantSellerFiltersAbTestEnabled =
    redundantSellerFiltersAbTest?.variant && redundantSellerFiltersAbTest?.variant !== 'off'

  const {
    fetch: fetchSellerFilters,
    isLoading: isSellerFiltersLoading,
    transformedData: sellerFilters,
    error: sellerFiltersError,
  } = useFetch(getSellerFilters, transformGetSellerFiltersResponse)

  useEffect(() => {
    if (!isRedundantSellerFiltersAbTestEnabled) return

    fetchSellerFilters()
  }, [fetchSellerFilters, isRedundantSellerFiltersAbTestEnabled])

  return {
    sellerFilters,
    isSellerFiltersLoading,
    sellerFiltersError,
  }
}

export default useGetSellerFilters
