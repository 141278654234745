'use client'

import { Chip, Spacer, Text } from '@vinted/web-ui'

import { useMemo } from 'react'

import HorizontalScrollArea from 'components/HorizontalScrollArea'
import SeparatedList from 'components/SeparatedList'
import useTranslate from 'hooks/useTranslate'

import useGetSellerFilters from 'pages/Profile/hooks/useFetchSellerFilters'

import useAbTest from 'hooks/useAbTest'

import { SellerFiltersType } from './utils'

type Props = {
  selectedSellerFilter: SellerFiltersType | undefined
  onFilterClick: (filter: SellerFiltersType) => void
}

const SellerFilters = ({ selectedSellerFilter, onFilterClick }: Props) => {
  const translate = useTranslate()
  const { sellerFilters, isSellerFiltersLoading, sellerFiltersError } = useGetSellerFilters()

  const onClick = (filter: SellerFiltersType) => () => {
    onFilterClick(filter)
  }

  const redundantSellerFiltersAbTest = useAbTest({
    abTestName: 'remove_redundant_wardrobe_filters',
    shouldTrackExpose: true,
  })

  const isRedundantSellerFiltersAbTestEnabled = redundantSellerFiltersAbTest?.variant !== 'off'

  const filtersToUse = useMemo(() => {
    if (sellerFilters && isRedundantSellerFiltersAbTestEnabled) {
      // We remove `ALL` and those filters that have no items behind and return just their keys
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const nonZeroFilters = Object.entries(sellerFilters).filter(([_, count]) => Boolean(count))
      const allowedFilters = nonZeroFilters.filter(([name]) => SellerFiltersType[name])
      const filters = allowedFilters.map(([name]) => name)

      return filters
    }

    // We return all filters if the endpoint returns no data
    if (!sellerFilters || sellerFiltersError) return Object.keys(SellerFiltersType)

    return []
  }, [sellerFilters, isRedundantSellerFiltersAbTestEnabled, sellerFiltersError])

  const renderViewSelfFilterList = () => {
    return (
      <SeparatedList
        separator={<Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Medium} />}
      >
        {filtersToUse.map(filter => {
          const filterKey = filter.toLowerCase()

          return (
            <Chip
              activated={selectedSellerFilter === SellerFiltersType[filter]}
              testId={`closet-seller-filters-${SellerFiltersType[filter] as string}`}
              text={translate(`profile.item_filters.${filterKey}`)}
              textType={Text.Type.Subtitle}
              onClick={onClick(SellerFiltersType[filter])}
              key={`closet-seller-filters-${filterKey}`}
            />
          )
        })}
      </SeparatedList>
    )
  }

  if (isSellerFiltersLoading || !filtersToUse.length) return null

  return (
    <>
      <div className="u-phones-only">
        <Spacer />
      </div>
      <HorizontalScrollArea showControls={false} disableIosSmoothScroll>
        <div className="u-flexbox" data-testid="closet-seller-filters">
          {renderViewSelfFilterList()}
        </div>
      </HorizontalScrollArea>
    </>
  )
}

export default SellerFilters
