'use client'

import { useSelector } from 'react-redux'
import { Button, Dialog, Text } from '@vinted/web-ui'

import FaqEntryUrl from 'components/FaqEntryUrl'
import useTranslate from 'hooks/useTranslate'
import { FaqEntryType } from 'constants/faq-entry'
import { getUserId } from 'state/session/selectors'
import { blockUser, unblockUser } from 'data/api'

type Props = {
  username: string
  userId: number
  isUserBlocked: boolean
  isVisible: boolean
  onCancel: () => void
  onConfirm: () => void
}

const UserBlockModal = ({
  userId,
  username,
  isUserBlocked,
  isVisible,
  onCancel,
  onConfirm,
}: Props) => {
  const translationKey = isUserBlocked ? 'unblock_modal' : 'block_modal'
  const translate = useTranslate(`user.${translationKey}`)
  const currentUserId = useSelector(getUserId)

  const getLearnMoreLink = () => (
    <FaqEntryUrl
      type={FaqEntryType.BlockingMember}
      render={url => (
        <a href={url} target="_blank" rel="noreferrer">
          {translate('learn_more_link')}
        </a>
      )}
    />
  )

  const handleToggleBlockClick = async () => {
    if (!currentUserId) return

    const toggleFunction = isUserBlocked ? unblockUser : blockUser
    await toggleFunction({ currentUserId, blockedUserId: userId })

    onConfirm()
  }

  return (
    <Dialog
      show={isVisible}
      title={translate('title', { username })}
      body={<Text text={translate('body', { learn_more_link: getLearnMoreLink() })} html />}
      testId="user-block-modal"
      actions={[
        {
          text: isUserBlocked ? translate('actions.unblock') : translate('actions.block'),
          callback: handleToggleBlockClick,
          style: Button.Styling.Filled,
          theme: isUserBlocked ? 'primary' : 'warning',
          testId: `user-block-modal--${isUserBlocked ? 'unblock' : 'block'}`,
        },
        {
          text: translate('actions.cancel'),
          callback: onCancel,
          theme: isUserBlocked ? 'primary' : 'muted',
          style: Button.Styling.Flat,
          testId: 'user-block-modal--cancel',
        },
      ]}
    />
  )
}

export default UserBlockModal
