'use client'

import { useState, ChangeEvent, useRef, useEffect } from 'react'
import { Button, Cell, Dialog, Navigation, Spacer, Text, InputTextArea } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import ScrollableArea from 'components/ScrollableArea'
import { FeedbackRatingModel } from 'types/models'
import { UpdateFeedbackRatingOptionsArgs } from 'types/api'

import { FaceIcon, OptionsSection } from './components'

type Props = {
  onClose: () => void
  onSubmit: (data: Omit<UpdateFeedbackRatingOptionsArgs, 'conversationId' | 'userId'>) => void
  isOpen: boolean
  ratings?: FeedbackRatingModel
  selectedRating?: number
  isOptionsVisible?: boolean
  isInputWithoutRatingOptions?: boolean
  isCloseActionVisible?: boolean
}

type SelectedOptionType = {
  [rating: number]: {
    isInputActive: boolean
    options: Array<number>
  }
}

const FeedbackFormModal = ({
  ratings,
  selectedRating,
  isOpen,
  onClose,
  onSubmit,
  isOptionsVisible = true,
  isInputWithoutRatingOptions = false,
  isCloseActionVisible = true,
}: Props) => {
  const [currentRating, setCurrentRating] = useState<number | undefined>(selectedRating)
  const [selectedFeedback, setSelectedFeedback] = useState<SelectedOptionType>()
  const [message, setMessage] = useState<string>('')

  const inputRef = useRef<HTMLTextAreaElement>(null)

  const translate = useTranslate('help_center_feedback')

  useEffect(() => {
    setCurrentRating(selectedRating)
  }, [selectedRating])

  useEffect(() => {
    if (!selectedFeedback || !currentRating) return

    inputRef.current?.focus()
  }, [currentRating, selectedFeedback])

  function updateFeedbackOptions({
    finalized,
    rating = currentRating,
  }: {
    finalized?: boolean
    rating?: number
  }) {
    const feedback = selectedFeedback || {}

    if (!rating) return

    const options = feedback[rating]?.options
    const isInputActive = feedback[rating]?.isInputActive

    if (finalized) {
      onSubmit({
        finalized,
        rating,
        message: isInputActive || isInputWithoutRatingOptions ? message : undefined,
        feedbackStatementIds: options?.length ? options : undefined,
      })

      return
    }

    onSubmit({ rating })
  }

  function handleSubmit() {
    updateFeedbackOptions({ finalized: true })
  }

  function handleClose() {
    setSelectedFeedback(undefined)
    setCurrentRating(undefined)
    setMessage('')
    onClose()
  }

  function handleFaceSelect(rating: number) {
    updateFeedbackOptions({ rating })
    setCurrentRating(rating)
  }

  function handleMessageTyping({ target }: ChangeEvent<HTMLTextAreaElement>) {
    setMessage(target.value)
  }

  function handleOptionSelect(id: number, hasInput: boolean) {
    if (!currentRating) return

    const feedback = selectedFeedback || {}
    // eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion
    const currentRatingSelection = feedback[currentRating]!?.options || []
    const isFeedbackOptionSelected = currentRatingSelection?.includes(id)
    // eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion
    const isInputActive = feedback[currentRating]!?.isInputActive

    const draftSelectedFeedback = {
      ...feedback,
      [currentRating]: {
        isInputActive: hasInput ? !isInputActive : isInputActive,
        options: isFeedbackOptionSelected
          ? currentRatingSelection.filter(option => option !== id)
          : [...currentRatingSelection, id],
      },
    }

    setSelectedFeedback(draftSelectedFeedback)
  }

  function renderRatings() {
    if (!ratings) return null

    return (
      <Cell>
        <div className="u-flexbox u-justify-content-center">
          {ratings.ratingIds.map((id: number) => (
            <FaceIcon
              key={id}
              rating={id}
              isActive={currentRating === id}
              onClick={handleFaceSelect}
            />
          ))}
        </div>
      </Cell>
    )
  }

  function renderOptions() {
    if (!ratings || !currentRating || !isOptionsVisible) return null

    const { options, title } = ratings.ratingOptions[currentRating]!
    const selectedOptions = selectedFeedback?.[currentRating]?.options

    return (
      <OptionsSection
        onClick={handleOptionSelect}
        ratingOptionsTitle={title}
        options={options}
        selectedValues={selectedOptions}
      />
    )
  }

  function renderInput() {
    let isInputActive = false

    if (currentRating && isInputWithoutRatingOptions) {
      isInputActive = true
    } else if (!currentRating || !selectedFeedback) {
      return null
    } else {
      // eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion
      isInputActive = selectedFeedback[currentRating]!?.isInputActive
    }

    if (!isInputActive) return null

    return (
      <>
        <div className="feedback-input__title">
          <Text text={translate('feedback_resolved.input_title')} type={Text.Type.Title} />
        </div>
        <InputTextArea
          ref={inputRef}
          maxRows={5}
          value={message}
          name="feedback_deny_comment"
          placeholder={translate('feedback_resolved.input_placeholder')}
          onChange={handleMessageTyping}
        />
      </>
    )
  }

  function renderActionButtons() {
    if (!currentRating) return null

    return (
      <div className="feedback-modal__actions">
        <Button
          text={translate('actions.submit_feedback')}
          styling={Button.Styling.Filled}
          onClick={handleSubmit}
          testId="feedback-resolved-submit"
        />
        {isCloseActionVisible && (
          <Button
            text={translate('actions.close')}
            styling={Button.Styling.Flat}
            onClick={handleClose}
            testId="feedback-resolved-close-button"
          />
        )}
      </div>
    )
  }

  return (
    <Dialog testId="feedback-modal" show={isOpen} hasScrollableContent>
      <div className="u-fill-width">
        <Navigation
          theme="transparent"
          right={
            <Button
              styling={Button.Styling.Flat}
              onClick={handleClose}
              iconName={X24}
              testId="feedback-resolved-close"
              aria={{ 'aria-label': translate('actions.close') }}
            />
          }
        />
        <ScrollableArea>
          <Text
            width={Text.Width.Parent}
            alignment={Text.Alignment.Center}
            text={ratings?.titleText || translate('feedback_resolved.title')}
            type={Text.Type.Heading}
          />
          <Text
            width={Text.Width.Parent}
            alignment={Text.Alignment.Center}
            text={ratings?.subtitleText}
            type={Text.Type.Subtitle}
          />
          {renderRatings()}
          {renderOptions()}
          {renderInput()}
          {renderActionButtons()}
        </ScrollableArea>
        <Spacer size={Spacer.Size.Large} />
      </div>
    </Dialog>
  )
}

export default FeedbackFormModal
