'use client'

import { Chip, Spacer, Text } from '@vinted/web-ui'

import { FeedbackOptionsModel } from 'types/models'

type Props = {
  onClick: (ids: number, hasInput: boolean) => void
  options: Array<FeedbackOptionsModel>
  ratingOptionsTitle: string
  selectedValues?: Array<number>
}

const OptionsSection = ({ onClick, options, ratingOptionsTitle, selectedValues }: Props) => {
  const renderChip = ({ body, id, hasMessageInput }: FeedbackOptionsModel) => {
    const isActive = selectedValues?.includes(id)

    const handleClick = () => {
      onClick(id, hasMessageInput)
    }

    return (
      <span key={id}>
        <Chip
          testId={`feedback-statement-${id}`}
          text={body}
          textType={Text.Type.Subtitle}
          activated={isActive}
          type="button"
          onClick={handleClick}
          aria={{ 'aria-pressed': isActive }}
        />
        <Spacer orientation={Spacer.Orientation.Vertical} />
        <Spacer />
      </span>
    )
  }

  return (
    <div className="u-ui-padding-horizontal-large">
      <Text text={ratingOptionsTitle} type={Text.Type.Title} />
      <Spacer size={Spacer.Size.Large} />
      <div className="u-flexbox u-flex-wrap u-align-items-center">{options.map(renderChip)}</div>
    </div>
  )
}

export { OptionsSection }
