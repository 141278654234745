'use client'

import { useEffect, useState } from 'react'
import { Badge, Card, Cell, Image, Spacer, Text } from '@vinted/web-ui'
import classNames from 'classnames'

import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import useLocation from 'hooks/useLocation'

import { ProfileCollectionModel } from 'types/models/collections'
import { EDIT_FEATURED_COLLECTION_URL, MEMBER_PROFILE_URL } from 'constants/routes'
import { clickListItemEvent } from 'libs/common/event-tracker/events'
import { ContentSource } from 'constants/tracking/content-sources'
import { ListItemContentType } from 'constants/tracking/content-types'
import { navigateToPage } from 'libs/utils/window'

import CollectionViewModal from '../CollectionViewModal'

const EMPTY_COLLECTION_IMAGE_PATH = 'hanger-empty-state-96.svg'

type Props = {
  collection: ProfileCollectionModel
  viewingSelf: boolean
  index: number
  userId: number
  viewedCollectionId?: number
}

const CollectionBox = ({ collection, viewingSelf, index, userId, viewedCollectionId }: Props) => {
  const translate = useTranslate('featured_collection.multiple.collections_block')
  const { track } = useTracking()
  const asset = useAsset('/assets/no-photo')
  const { replaceHistoryState } = useLocation()

  const [isCollectionViewModalOpen, setIsCollectionViewModalOpen] = useState(false)

  const { id, title, itemsCount, itemsPhotos, isActive, humanizedDiscountAmount } = collection

  const openModalOnLoad = viewedCollectionId === id

  const handleModalClose = () => {
    setIsCollectionViewModalOpen(false)
  }

  const handleModalCloseOnButtonClick = () => {
    replaceHistoryState(MEMBER_PROFILE_URL(userId))

    handleModalClose()
  }

  const handleModalOpen = () => {
    setIsCollectionViewModalOpen(true)
  }

  useEffect(() => {
    if (!openModalOnLoad) handleModalClose()
    if (openModalOnLoad) handleModalOpen()
  }, [openModalOnLoad])

  const renderImage = (imgNumber: number) => {
    return (
      <div className={`multiple-collections-block__collage-image collage--image-${imgNumber}`}>
        <Image
          src={itemsPhotos[imgNumber - 1]}
          ratio={Image.Ratio.Square}
          scaling={Image.Scaling.Cover}
          testId={`collections-box-collage-image-${imgNumber}`}
        />
      </div>
    )
  }

  const renderImages = () => {
    if (itemsPhotos.length === 0) {
      return (
        <Card>
          <Image
            src={asset(EMPTY_COLLECTION_IMAGE_PATH)}
            testId="collections-box-empty-state"
            alt=""
            scaling={Image.Scaling.ScaleDown}
          />
        </Card>
      )
    }

    if (itemsPhotos.length < 3) {
      return renderImage(1)
    }

    return (
      <div className="multiple-collections-block__collage">
        {renderImage(1)}
        {renderImage(2)}
        {renderImage(3)}
      </div>
    )
  }

  const renderBadge = () => {
    if (!humanizedDiscountAmount) return null

    return (
      <div className="multiple-collections-block__box-content">
        <Cell styling={Cell.Styling.Narrow} theme="transparent">
          <div className="u-flexbox u-flex-direction-column u-align-items-flex-start u-gap-small">
            <Badge
              theme="warning"
              content={humanizedDiscountAmount}
              testId={`collection-box-discount-badge-${id}`}
            />
          </div>
        </Cell>
      </div>
    )
  }

  const handleNavigation = () => {
    navigateToPage(EDIT_FEATURED_COLLECTION_URL(id))
  }

  const handleCollectionClick = () => {
    const shouldRedirectToEdit = itemsCount === 0

    track(
      clickListItemEvent({
        id,
        position: index + 1,
        contentType: ListItemContentType.Item,
        contentSource: ContentSource.FeaturedCollection,
      }),
    )

    if (!shouldRedirectToEdit) {
      replaceHistoryState(MEMBER_PROFILE_URL(userId, id))

      return handleModalOpen()
    }

    return handleNavigation()
  }

  const renderNavigationOverlay = () => {
    return (
      <div className="multiple-collections-block__box-content u-flexbox u-fill-height u-fill-width">
        <Cell
          type={Cell.Type.Navigating}
          theme="transparent"
          onClick={handleCollectionClick}
          testId="collection-box-overlay"
          aria={{ 'aria-labelledby': `collection-${collection.id}-title` }}
        />
      </div>
    )
  }

  return (
    <>
      <div className="u-flexbox u-flex-direction-column" data-testid={`collection-${id}`}>
        <div
          className={classNames(
            'multiple-collections-block__square-box-container u-position-relative',
            { 'multiple-collections-block__square-box-container--inactive': !isActive },
          )}
        >
          {renderImages()}
          {renderBadge()}
          {renderNavigationOverlay()}
        </div>
        <Spacer />
        <div className="u-flexbox u-flex-direction-column">
          <div className="u-text-wrap multiple-collections-block__box-title">
            <Text
              id={`collection-${collection.id}-title`}
              text={title}
              type={Text.Type.Body}
              theme="amplified"
              width={Text.Width.Parent}
            />
          </div>
          <Text
            text={translate('collection_item_count', { count: itemsCount }, { count: itemsCount })}
            type={Text.Type.Caption}
            theme="muted"
          />
        </div>
      </div>
      {isCollectionViewModalOpen && (
        <CollectionViewModal
          isShown={isCollectionViewModalOpen}
          onClose={handleModalCloseOnButtonClick}
          viewingSelf={viewingSelf}
          collectionId={id}
        />
      )}
    </>
  )
}

export default CollectionBox
