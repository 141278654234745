'use client'

import { X24 } from '@vinted/monochrome-icons'
import { Button, Cell, Divider, Dialog, Text, Spacer, Image } from '@vinted/web-ui'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import useAsset from 'hooks/useAsset'

import { ITEM_UPLOAD_URL, SELLING_HELP_URL, TERMS_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'
import { clickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import ScrollableArea from 'components/ScrollableArea'

type Props = {
  show: boolean
  onClose: () => void
  itemsCount: number
}

const BadgeInfoModal = ({ show, onClose, itemsCount }: Props) => {
  const translate = useTranslate('profile.badges.active_lister.modal')
  const { track } = useTracking()
  const asset = useAsset('/assets/user-badges')

  const handleOnClose = () => {
    track(clickEvent({ screen: Screen.ListerBadgeInfo, target: ClickableElement.CloseBadgeInfo }))
    onClose()
  }

  const handleUploadItemClick = () => {
    track(clickEvent({ screen: Screen.ListerBadgeInfo, target: ClickableElement.UploadItem }))
  }

  const renderHeader = () => {
    return (
      <>
        <Cell
          styling={Cell.Styling.Tight}
          prefix={<Spacer orientation={Spacer.Orientation.Horizontal} size={Spacer.Size.X3Large} />}
          suffix={
            <Button
              iconName={X24}
              styling={Button.Styling.Flat}
              onClick={handleOnClose}
              testId="badge-info-modal-close"
            />
          }
        >
          <Spacer size={Spacer.Size.Large} />
          <div className="u-text-center">
            <Text
              type={Text.Type.Title}
              alignment={Text.Alignment.Center}
              text={translate('title')}
            />
          </div>
          <Spacer size={Spacer.Size.Large} />
        </Cell>
        <Divider />
      </>
    )
  }

  const renderBody = () => {
    return (
      <>
        <Spacer size={Spacer.Size.Medium} />
        <div className="u-flexbox">
          <Image src={asset('hanger.svg')} size={Image.Size.X2Large} />
        </div>
        <Spacer size={Spacer.Size.XLarge} />
        <Text
          text={translate('body', {
            items_count: itemsCount,
            'catalog-rules-link': chunks => (
              <a href={SELLING_HELP_URL} target="_blank" rel="noopener noreferrer">
                {chunks}
              </a>
            ),
            'terms-and-conditions-link': chunks => (
              <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">
                {chunks}
              </a>
            ),
          })}
          html
        />
      </>
    )
  }

  const renderActions = () => {
    return (
      <>
        <Spacer size={Spacer.Size.X2Large} />
        <Button
          text={translate('actions.upload_item_now')}
          styling={Button.Styling.Filled}
          url={ITEM_UPLOAD_URL}
          onClick={handleUploadItemClick}
          testId="badge-info-modal-upload-item"
        />
      </>
    )
  }

  return (
    <Dialog show={show} closeOnOverlay defaultCallback={handleOnClose} hasScrollableContent>
      <div>
        {renderHeader()}
        <ScrollableArea>
          <Cell>
            {renderBody()}
            {renderActions()}
          </Cell>
        </ScrollableArea>
      </div>
    </Dialog>
  )
}

export default BadgeInfoModal
