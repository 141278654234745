'use client'

import { Screen } from 'constants/tracking/screens'
import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'
import Profile from 'pages/Profile/Profile'
import { getProfileUserInfoRequest, getUserByIdRequest } from 'data/api/requests'
import { ROOT_URL } from 'constants/routes'
import { UserAccountStatus } from 'constants/user'
import { UserThumbnailSize } from 'constants/images'

import PageHead from '../../../components/Head/PageHead'
import fullPageLayout from '../../../layouts/FullPage'
import { getLayoutServerSideProps } from '../../../layouts/FullPage/server-props'

const redirectToRoot = () => ({
  redirect: {
    destination: ROOT_URL,
    permanent: true,
  },
})

type Props = {
  userId: number
  userName: string
  userPhotoUrl: string | undefined
}

const ProfilePage = ({ userId, userName, userPhotoUrl }: Props) => {
  const translate = useTranslate('profile.page')
  const asset = useAsset('/assets/no-photo')

  return (
    <>
      <PageHead title={translate('title', { username: userName })}>
        <meta property="og:title" content={userName} />
        <meta property="og:image" content={userPhotoUrl || asset('user.png')} />
      </PageHead>
      <Profile userId={userId} />
    </>
  )
}

export default fullPageLayout<Props>(ProfilePage)

export const getServerSideProps = getLayoutServerSideProps(
  async (context, serverProps) => {
    const { userHash } = context.query
    const userId = typeof userHash === 'string' ? parseInt(userHash, 10) : null

    if (!userId) return redirectToRoot()

    const viewedUser = await getUserByIdRequest(serverProps.api)(userId, { throwError: true })
      .then(response => response.user)
      .catch(() => null)

    if (!viewedUser) return redirectToRoot()

    const hasSupportRole = async () => {
      const user = await serverProps.user
      if (!user) return false

      return getProfileUserInfoRequest(user.id)(serverProps.api)({
        throwError: true,
      })
        .then(response => response.show_support_functionality)
        .catch(() => false)
    }

    const isViewingSelf = viewedUser.id === serverProps.user?.id

    const canViewProfile =
      viewedUser.account_status !== UserAccountStatus.Deleted ||
      isViewingSelf ||
      (await hasSupportRole())

    if (!canViewProfile) {
      return {
        notFound: true,
      } as const
    }

    const userPhotoUrl = viewedUser.photo?.thumbnails.find(
      thumb => thumb.type === UserThumbnailSize.X6Large,
    )?.url

    return {
      props: {
        userId: viewedUser.id,
        userName: viewedUser.login,
        userPhotoUrl,
      },
    }
  },
  {
    id: 'member',
    screen: Screen.UserProfile,
  },
)
