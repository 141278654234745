'use client'

import { Button, Cell, Spacer } from '@vinted/web-ui'
import { useState } from 'react'
import { useInView } from 'react-intersection-observer'

import useTranslate from 'hooks/useTranslate'
import useBreakpoint from 'hooks/useBreakpoint'
import useTracking from 'hooks/useTracking'
import useFetchCollections from 'hooks/useFetchCollections'
import useLocation from 'hooks/useLocation'

import { FeaturedCollectionCheckout } from 'components/FeaturedCollection/FeaturedCollectionCheckout'
import HorizontalScrollArea from 'components/HorizontalScrollArea'
import { ControlScrollType } from 'components/HorizontalScrollArea/HorizontalScrollArea'

import { clickEvent, impressionEvent } from 'libs/common/event-tracker/events'
import { navigateToPage } from 'libs/utils/window'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { ProfileCollectionModel } from 'types/models/collections'
import { COLLECTIONS_MANAGEMENT_URL, MEMBER_PROFILE_URL } from 'constants/routes'
import { ListItemContentType } from 'constants/tracking/content-types'
import { ContentSource } from 'constants/tracking/content-sources'

import CollectionBox from './CollectionBox'
import AddNewCollectionButton from './AddNewCollectionButton'

type Props = {
  viewingSelf: boolean
  userId: number
}

const MultipleCollectionsBlock = ({ viewingSelf, userId }: Props) => {
  const { collectionsData, error } = useFetchCollections(userId)
  const translate = useTranslate('featured_collection.multiple.collections_block')
  const breakpoints = useBreakpoint()
  const { track } = useTracking()
  const { collection: collectionParam } = useLocation().searchParams
  const { ref } = useInView({
    triggerOnce: true,
    onChange: inView => {
      if (!inView) return
      track(
        impressionEvent({
          id: userId,
          position: 0,
          contentType: ListItemContentType.FeaturedCollection,
          contentSource: ContentSource.FeaturedCollection,
        }),
      )
    },
  })

  const viewedCollectionId = Number(collectionParam) || undefined

  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false)

  if (!collectionsData || error) return null

  const { humanizedTimeLeft, maxActiveCount, maxTotalCount, collections } = collectionsData

  const activeCollectionsCount = collections.filter(collection => collection.isActive).length
  const collectionsWithItemsCount = collections.filter(
    collection => collection.itemsCount > 0,
  ).length

  function handleManageClick() {
    track(
      clickEvent({ screen: Screen.CurrentUserProfile, target: ClickableElement.ManageCollections }),
    )
    navigateToPage(COLLECTIONS_MANAGEMENT_URL)
  }

  function handleActivateClick() {
    track(
      clickEvent({
        screen: Screen.CurrentUserProfile,
        target: ClickableElement.ActivateFeaturedCollections,
      }),
    )

    setIsCheckoutOpen(true)
  }

  function handleCheckoutClose() {
    setIsCheckoutOpen(false)
  }

  function handleCheckoutSuccess() {
    navigateToPage(MEMBER_PROFILE_URL(userId))
  }

  const renderTitle = () => {
    if (!viewingSelf) return translate('title')

    if (!maxActiveCount) return translate('preview_title')

    return translate('active_title', { count: activeCollectionsCount, max_count: maxActiveCount })
  }

  const renderSubtitle = () => {
    if (!viewingSelf) return null
    if (!maxActiveCount) return translate('subtitle')

    return humanizedTimeLeft
  }

  const renderManageButton = () => {
    if (!viewingSelf) return null

    return (
      <Button
        text={translate('actions.manage')}
        styling={Button.Styling.Flat}
        size={Button.Size.Medium}
        testId="collections-block-manage-button"
        onClick={handleManageClick}
      />
    )
  }

  const renderCollectionsInfo = () => {
    return (
      <>
        <Spacer size={Spacer.Size.Large} />
        <Cell
          title={renderTitle()}
          body={renderSubtitle()}
          styling={Cell.Styling.Tight}
          theme="transparent"
          suffix={renderManageButton()}
          testId="collections-block-info"
        />
        <Spacer size={Spacer.Size.Large} />
      </>
    )
  }

  const renderCollectionBox = (collection: ProfileCollectionModel, index: number) => {
    if (!viewingSelf && (!collection.isActive || collection.itemsCount === 0)) return null

    return (
      <HorizontalScrollArea.Item
        className="multiple-collections-block__scroll-item"
        key={`collection-${collection.id}`}
      >
        <CollectionBox
          collection={collection}
          viewingSelf={viewingSelf}
          index={index}
          userId={userId}
          viewedCollectionId={viewedCollectionId}
        />
      </HorizontalScrollArea.Item>
    )
  }

  const renderAddNewCollectionButton = () => {
    if (!viewingSelf || !maxTotalCount) return null
    if (collections.length >= maxTotalCount) return null

    return (
      <HorizontalScrollArea.Item className="multiple-collections-block__scroll-item">
        <AddNewCollectionButton />
      </HorizontalScrollArea.Item>
    )
  }

  const renderActivateCollectionButton = () => {
    if (!viewingSelf || !!maxActiveCount) return null

    return (
      <>
        <Button
          type={Button.Type.Button}
          text={translate('actions.activate')}
          styling={Button.Styling.Filled}
          theme="primary"
          onClick={handleActivateClick}
          size={breakpoints.phones ? Button.Size.Medium : undefined}
          inline={!breakpoints.phones}
          testId="collections-block-activate-button"
        />
        <Spacer size={Spacer.Size.XLarge} />
        <FeaturedCollectionCheckout
          show={isCheckoutOpen}
          onClose={handleCheckoutClose}
          onSuccess={handleCheckoutSuccess}
        />
      </>
    )
  }

  if (!collections.length && maxActiveCount === 0) return null
  if ((!viewingSelf && !activeCollectionsCount) || (!viewingSelf && !collectionsWithItemsCount))
    return null

  return (
    <div ref={ref} className="multiple-collections-block" data-testid="collections-block">
      <Spacer size={Spacer.Size.Large} />
      {renderCollectionsInfo()}
      <HorizontalScrollArea controlsScrollType={ControlScrollType.Partial}>
        {collections.map(renderCollectionBox)}
        {renderAddNewCollectionButton()}
      </HorizontalScrollArea>
      <Spacer size={Spacer.Size.Large} />
      {renderActivateCollectionButton()}
    </div>
  )
}

export default MultipleCollectionsBlock
