import { useEffect } from 'react'

import useFetch from 'hooks/useFetch'

import { getSellerInsights } from 'data/api'
import { transformGetSellerInsightsResponse } from 'data/api/transformers/response'
import useAbTest from 'hooks/useAbTest'

const useGetSellerInsights = () => {
  const sellerInsightsAbTest = useAbTest({
    abTestName: 'seller_insights_blocks_web',
    shouldTrackExpose: true,
  })

  const isSellerInsightsEnabled = sellerInsightsAbTest && sellerInsightsAbTest.variant !== 'off'

  const {
    fetch: fetchSellerInsights,
    isLoading: isSellerInsightsLoading,
    transformedData: sellerInsights,
  } = useFetch(getSellerInsights, transformGetSellerInsightsResponse)

  useEffect(() => {
    if (!isSellerInsightsEnabled) return

    fetchSellerInsights()
  }, [isSellerInsightsEnabled, fetchSellerInsights])

  return {
    sellerInsights,
    isSellerInsightsLoading,
  }
}

export default useGetSellerInsights
