'use client'

import { Card, Divider, Spacer, Text, Button, Cell } from '@vinted/web-ui'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { getLocale } from 'state/intl/selectors'
import { formatCurrencyAmount } from 'libs/utils/formatString'
import { clickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'

import ContentLoader from 'components/ContentLoader'
import { FormattedMessage } from 'components/@support'

import useGetSellerInsights from './hooks/useGetSellerInsights'
import SellerInsightsValuePropositionModal from './SellerInsightsValuePropositionModal/SellerInsightsValuePropositionModal'

const SellerInsights = () => {
  const { sellerInsights, isSellerInsightsLoading } = useGetSellerInsights()
  const { track } = useTracking()
  const [openModal, setOpenModal] = useState(false)

  const locale = useSelector(getLocale)
  const translate = useTranslate('profile.seller_insights')

  const activeItemsPrice = sellerInsights?.activeItemsStats.total
  const soldItemsPrice = sellerInsights?.soldItemsStats.total
  const recentItemsPrice = sellerInsights?.soldItemsStats.period.total
  const recentItemsPeriod = sellerInsights?.soldItemsStats.period.duration

  const handleOpenModal = () => {
    track(
      clickEvent({
        target: ClickableElement.SellerInsightsSeeMore,
        screen: Screen.CurrentUserProfile,
      }),
    )
    setOpenModal(true)
  }

  const handleCloseModal = () => setOpenModal(false)

  const handleClickBlocks = () => {
    track(
      clickEvent({
        target: ClickableElement.SellerInsights,
        screen: Screen.CurrentUserProfile,
      }),
    )
  }

  if (isSellerInsightsLoading) return <ContentLoader testId="seller-insights-loader" />

  if (!sellerInsights?.isVisible) return null

  return (
    <section>
      <Spacer size={Spacer.Size.Medium} />
      <div className="u-flexbox u-flex-direction-row u-align-items-center u-justify-content-between">
        <Text text={translate('title')} type={Text.Type.Caption} as="h2" />
        <Button
          inline
          text={translate('link')}
          size={Button.Size.Small}
          styling={Button.Styling.Flat}
          onClick={handleOpenModal}
        />
      </div>
      <Spacer size={Spacer.Size.Small} />
      <Card>
        <Cell
          testId="seller-insights-blocks"
          onClick={handleClickBlocks}
          styling={Cell.Styling.Tight}
        >
          <div className="u-flexbox u-flex-direction-row u-padding-medium">
            <section className="u-flexbox u-flex-direction-column u-align-items-center u-half-width">
              <Text
                text={translate('blocks.wardrobe_value.title')}
                type={Text.Type.Caption}
                as="h3"
              />
              <Text
                text={activeItemsPrice ? formatCurrencyAmount(activeItemsPrice, locale) : ''}
                type={Text.Type.Title}
              />
              <Text type={Text.Type.Caption}>
                <FormattedMessage
                  pluralize
                  count={sellerInsights?.activeItemsStats.count}
                  id="profile.seller_insights.blocks.wardrobe_value.subtitle"
                  values={{ wardrobe_items_count: sellerInsights?.activeItemsStats.count }}
                />
              </Text>
            </section>
            <Divider orientation={Divider.Orientation.Vertical} />
            <section className="u-flexbox u-flex-direction-column u-align-items-center u-half-width">
              <Text text={translate('blocks.earnings.title')} type={Text.Type.Caption} as="h3" />
              <Text
                text={soldItemsPrice ? formatCurrencyAmount(soldItemsPrice, locale) : ''}
                type={Text.Type.Title}
              />
              <Text type={Text.Type.Caption} theme="success">
                {translate('blocks.earnings.subtitle', {
                  total_earned: recentItemsPrice
                    ? formatCurrencyAmount(recentItemsPrice, locale)
                    : '',
                })}
              </Text>
            </section>
          </div>
        </Cell>
      </Card>
      <Spacer size={Spacer.Size.Medium} />
      <SellerInsightsValuePropositionModal
        show={openModal}
        onCloseOrConfirm={handleCloseModal}
        period={recentItemsPeriod}
      />
    </section>
  )
}

export default SellerInsights
