'use client'

import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import {
  Text,
  Dialog,
  Button,
  Navigation,
  Cell,
  Image,
  Divider,
  Icon,
  ColorCode,
  InfoBanner,
} from '@vinted/web-ui'
import { Bookmark24, Percent24, Stack24, X24 } from '@vinted/monochrome-icons'

import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'
import useFetch from 'hooks/useFetch'
import useAbTest from 'hooks/useAbTest'

import ContentLoader from 'components/ContentLoader'
import ScrollableArea from 'components/ScrollableArea'

import { formatCurrencyAmount } from 'libs/utils/formatString'
import { transformFeaturedCollectionPricingResponse } from 'data/api/transformers/response'
import { exposeVasBannerLock, getFeaturedCollectionPricing } from 'data/api'

type Props = {
  show: boolean
  isFeaturedCollectionCreated: boolean
  onNextAction: () => void
  onBack: () => void
}

const FeaturedCollectionOnboardingModal = ({
  show,
  isFeaturedCollectionCreated,
  onNextAction,
  onBack,
}: Props) => {
  const translate = useTranslate('featured_collection.onboarding_modal')
  const { locale } = useIntl()
  const asset = useAsset('/assets/featured-collection')
  const vasBannerLockAbTest = useAbTest({ abTestName: 'vas_banner_lock' })

  const {
    fetch: fetchPricing,
    isLoading,
    transformedData: pricing,
    error,
  } = useFetch(getFeaturedCollectionPricing, transformFeaturedCollectionPricingResponse)

  useEffect(() => {
    if (!vasBannerLockAbTest?.variant) return
    if (!pricing) return

    exposeVasBannerLock()
  }, [vasBannerLockAbTest, pricing])

  const isFetchPricingInitiated = !!pricing || isLoading || error

  useEffect(() => {
    if (!show || isFetchPricingInitiated) return

    fetchPricing()
  }, [fetchPricing, show, isFetchPricingInitiated])

  function renderNavigation() {
    return (
      <Navigation
        right={
          <Button
            iconName={X24}
            theme="amplified"
            styling={Button.Styling.Flat}
            testId="featured-collection-onboarding-back-button"
            onClick={onBack}
          />
        }
        body={
          <Text
            type={Text.Type.Title}
            width={Text.Width.Parent}
            alignment={Text.Alignment.Center}
            text={translate('title')}
          />
        }
      />
    )
  }

  function renderIntro() {
    return (
      <>
        <Cell>
          <Text text={translate('intro.title')} type={Text.Type.Heading} />
        </Cell>
        <Cell
          title={translate('intro.profile.title')}
          body={translate('intro.profile.body')}
          prefix={<Icon name={Stack24} color={ColorCode.GreyscaleLevel3} />}
        />
        <Cell
          title={translate('intro.homepage.title')}
          body={translate('intro.homepage.body')}
          prefix={<Icon name={Bookmark24} color={ColorCode.GreyscaleLevel3} />}
        />
        <Cell
          title={translate('intro.sale.title')}
          body={translate('intro.sale.body')}
          prefix={<Icon name={Percent24} color={ColorCode.GreyscaleLevel3} />}
        />
      </>
    )
  }

  function renderDeal() {
    if (!pricing) return null

    const pricePerDay = formatCurrencyAmount(pricing.pricePerDay, locale)
    const totalPrice = formatCurrencyAmount(pricing.totalPrice, locale)

    return (
      <Cell
        title={translate(
          'duration_day_count',
          { count: pricing.effectiveDays },
          { count: pricing.effectiveDays },
        )}
        body={translate('price_per_day', { price: pricePerDay })}
        suffix={<Text text={totalPrice} type={Text.Type.Title} />}
      />
    )
  }

  function renderConfirmationAction() {
    if (!pricing) return null

    const confirmationKey = isFeaturedCollectionCreated ? 'review' : 'create'

    return (
      <div className="u-ui-margin-horizontal-large u-ui-margin-bottom-large">
        <Button
          text={translate(`actions.${confirmationKey}`)}
          styling={Button.Styling.Filled}
          onClick={onNextAction}
          testId={`featured-collection-onboarding-${confirmationKey}-button`}
        />
      </div>
    )
  }

  function renderLockedInfoBanner() {
    return (
      <div className="u-ui-margin-horizontal-large u-ui-margin-bottom-large">
        <InfoBanner
          body={pricing?.lockedNote}
          styling={InfoBanner.Styling.Tight}
          testId="locked-banner"
        />
      </div>
    )
  }

  function renderContent() {
    if (isLoading) {
      return <ContentLoader styling={ContentLoader.Styling.Wide} />
    }

    const imageSrc = asset('featured-collection-onboarding.png', {
      theme: { dark: 'dark/featured-collection-onboarding.png' },
    })

    return (
      <>
        <Image src={imageSrc} testId="featured-collection-onboarding-image" />
        {renderIntro()}
      </>
    )
  }

  return (
    <Dialog show={show} hasScrollableContent testId="featured-collection-onboarding-modal">
      <div className="u-fill-width">
        {renderNavigation()}
        <ScrollableArea>{renderContent()}</ScrollableArea>
        <Divider />
        {renderDeal()}
        {pricing?.locked && renderLockedInfoBanner()}
        {!pricing?.locked && renderConfirmationAction()}
      </div>
    </Dialog>
  )
}

export default FeaturedCollectionOnboardingModal
