'use client'

import { Divider, Spacer } from '@vinted/web-ui'

import useBreakpoint from 'hooks/useBreakpoint'

import { UserDto } from 'types/dtos'
import { isDateInTheFuture } from 'libs/utils/date'

import ProfileVisitorBanners from './ProfileVisitorBanners'
import ProfileOwnerBanners from './ProfileOwnerBanners'
import ProfileCountdownBanner from './ProfileCountdownBanner'

type Props = {
  viewingSelf: boolean
  showActions: boolean
  user: UserDto | null | undefined
  totalItemsCount: number
  userId: number
}

const ProfileBanners = ({ showActions, viewingSelf, user, totalItemsCount, userId }: Props) => {
  const breakpoints = useBreakpoint()

  const renderCountDownBanner = () => {
    const {
      is_favourite: isFollowed,
      login: username,
      closet_countdown_end_date: closetCountdownEndDate,
    } = user || {}

    if (!closetCountdownEndDate || !isDateInTheFuture(closetCountdownEndDate)) return null

    const bottomElement = breakpoints.tabletsUp ? <Spacer size={Spacer.Size.XLarge} /> : <Divider />

    return (
      <ProfileCountdownBanner
        userId={userId}
        username={username}
        isFollowed={isFollowed}
        showActions={showActions}
        viewingSelf={viewingSelf}
        endDate={closetCountdownEndDate}
        bottomElement={viewingSelf ? bottomElement : undefined}
      />
    )
  }

  return viewingSelf ? (
    <ProfileOwnerBanners userId={userId} renderCountDownBanner={renderCountDownBanner} />
  ) : (
    <ProfileVisitorBanners
      user={user}
      totalItemsCount={totalItemsCount}
      userId={userId}
      renderCountDownBanner={renderCountDownBanner}
    />
  )
}

export default ProfileBanners
