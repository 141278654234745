import { InfoBanner, Text } from '@vinted/web-ui'

import { EprBannerModel } from 'types/models'

type Props = {
  banner: EprBannerModel
}

enum EprBannerType {
  Info = 'epr_info',
  Warning = 'epr_warning',
}

const EprBanner = ({ banner }: Props) => {
  const {
    name,
    title,
    body,
    addUinButtonLabel,
    addUinButtonUrl,
    learnMoreButtonLabel,
    learnMoreButtonUrl,
  } = banner

  const bannerType =
    name && name === EprBannerType.Warning ? InfoBanner.Type.Warning : InfoBanner.Type.Info

  return (
    <InfoBanner
      type={bannerType}
      title={title}
      body={<Text text={body} />}
      actions={[
        {
          text: addUinButtonLabel,
          callback: () => undefined,
          url: addUinButtonUrl,
        },
        { text: learnMoreButtonLabel, callback: () => undefined, url: learnMoreButtonUrl },
      ]}
    />
  )
}

export default EprBanner
