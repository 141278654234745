'use client'

import { ChangeEvent, FormEvent, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Cell, InputBar, Spacer, Validation } from '@vinted/web-ui'

import { ResponseCode } from 'data/api/response-codes'
import { UiState } from 'constants/ui'

import useTranslate from 'hooks/useTranslate'
import { COMMENT_CHAR_LIMIT } from 'types/models'

import { FeedbackScreen, useFeedbackContext } from '../../FeedbackProvider'

type Props = {
  id: number
}

const CommentInput = ({ id }: Props) => {
  const { getFeedbackById, getFeedbackItemStateById, updateFeedbackItemStateById, editComment } =
    useFeedbackContext()
  const translate = useTranslate('user.feedback')

  const feedback = getFeedbackById(id)
  const feedbackState = getFeedbackItemStateById(id)

  const comment = feedback?.comment
  const uiState = feedbackState?.uiState
  const feedbackSubmitError = feedbackState?.error

  const [text, setText] = useState<string>(comment?.text || '')
  const [error, setError] = useState<string | null>(null)

  const handleChange = (event: ChangeEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>) =>
    setText(event.currentTarget.value)

  const handleValueClear = () => setText('')

  const handleCancel = () => {
    updateFeedbackItemStateById(id, {
      uiState: UiState.Idle,
      screen: FeedbackScreen.View,
      error: null,
    })
  }

  const handleEdit = () => {
    if (!text) {
      setError(translate('empty_message'))

      return
    }

    if (error) setError(null)

    editComment(id, text)
  }

  const renderValidation = () => {
    let message: string

    if (error) {
      message = error
    } else {
      if (
        uiState !== UiState.Failure ||
        !feedbackSubmitError?.errors ||
        feedbackSubmitError.code !== ResponseCode.ValidationError
      ) {
        return null
      }

      const failingField = feedbackSubmitError.errors.find(({ field }) => field === 'comment')

      if (!failingField) return null

      message = failingField.value
    }

    return (
      <>
        <Spacer />
        <Validation text={message} theme="warning" />
      </>
    )
  }

  return (
    <Cell>
      <InputBar
        placeholder=""
        name="create-comment"
        value={text}
        onChange={handleChange}
        onValueClear={handleValueClear}
        isMultiline
        maxRows={1}
        maxLength={COMMENT_CHAR_LIMIT}
        testId="feedback-comment-input"
      />
      {renderValidation()}
      <Spacer />
      <Button
        text={<FormattedMessage id="user.feedback.cancel" />}
        size={Button.Size.Medium}
        styling={Button.Styling.Flat}
        inline
        onClick={handleCancel}
        testId="feedback-reply-cancel"
      />
      <Spacer orientation={Spacer.Orientation.Vertical} />
      <Button
        text={<FormattedMessage id="user.feedback.reply" />}
        size={Button.Size.Medium}
        styling={Button.Styling.Filled}
        inline
        onClick={handleEdit}
        isLoading={uiState === UiState.Pending}
        testId="feedback-reply-submit"
      />
    </Cell>
  )
}

export default CommentInput
