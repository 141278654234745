'use client'

import { useState } from 'react'
import { Button, Text } from '@vinted/web-ui'

import Banner from 'components/Banner'
import { BusinessAccountBannerModel } from 'types/models'

import CancelRegistrationModal from './CancelRegistrationModal'

type Props = {
  banner: BusinessAccountBannerModel
}

const BusinessAccountBanner = ({ banner }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { title, body, continueButtonLabel, continueButtonUrl, cancelButtonLabel } = banner

  function handleCancelClick() {
    setIsModalOpen(true)
  }

  function handleModalClose() {
    setIsModalOpen(false)
  }

  return (
    <>
      <Banner
        title={<Text text={title} type={Text.Type.Heading} />}
        body={<Text text={body} />}
        actions={[
          <Button
            text={continueButtonLabel}
            url={continueButtonUrl}
            styling={Button.Styling.Filled}
          />,
          <Button text={cancelButtonLabel} onClick={handleCancelClick} />,
        ]}
      />
      <CancelRegistrationModal isOpen={isModalOpen} onClose={handleModalClose} />
    </>
  )
}

export default BusinessAccountBanner
