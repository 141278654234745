'use client'

import { Spacer } from '@vinted/web-ui'
import { useSelector } from 'react-redux'

import { Screen } from 'constants/tracking/screens'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { TaxpayerBannerModel } from 'types/models'

import TaxpayerBanner from 'components/TaxpayerBanner'
import { TaxpayerBannerRenderLocation } from 'constants/taxpayer'

type Props = {
  taxpayerBanner?: TaxpayerBannerModel
}

const ProfileTaxpayerBanner = ({ taxpayerBanner }: Props) => {
  const isTaxpayerSpecialVerificationBannerFsEnabled = useSelector(
    getIsFeatureSwitchEnabled('web_special_verification_taxpayers_banners'),
  )

  if (!taxpayerBanner) return null

  const isTaxpayersSpecialVerificationBanner = taxpayerBanner.isSpecialVerification

  const shouldDisplayBanner =
    !isTaxpayersSpecialVerificationBanner ||
    (isTaxpayersSpecialVerificationBanner && isTaxpayerSpecialVerificationBannerFsEnabled)

  if (!shouldDisplayBanner) return null

  return (
    <>
      <TaxpayerBanner
        banner={taxpayerBanner}
        isBannerInFeed={false}
        screen={Screen.CurrentUserProfile}
        renderLocation={TaxpayerBannerRenderLocation.Wardrobe}
      />
      <Spacer size={Spacer.Size.Large} />
    </>
  )
}

export default ProfileTaxpayerBanner
