'use client'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ListPromotionModal from 'components/ListPromotionModal'
import * as selectors from 'state/profile/selectors'
import { PromoAfterItemUpload } from 'state/profile/constants'
import { startPromosAfterItemUpload } from 'state/profile/actions'

import ItemUploadFeedback from './ItemUploadFeedback'
import ItemUploadFeedbackNotification from './ItemUploadFeedbackNotification'

const PromosAfterItemUpload = () => {
  const [isFeedbackNotificationOpen, setIsFeedbackNotificationOpen] = useState(false)
  const dispatch = useDispatch()
  const currentPromo = useSelector(selectors.getCurrentPromotion)

  useEffect(() => {
    dispatch(startPromosAfterItemUpload())
  }, [dispatch])

  const handleNotificationShow = () => {
    setIsFeedbackNotificationOpen(true)
  }

  const closeNotification = () => {
    setIsFeedbackNotificationOpen(false)
  }

  return (
    <>
      {currentPromo === PromoAfterItemUpload.ListAnotherItem && <ListPromotionModal />}
      {currentPromo === PromoAfterItemUpload.ItemUploadFeedback && (
        <ItemUploadFeedback onClose={handleNotificationShow} />
      )}
      {isFeedbackNotificationOpen && (
        <ItemUploadFeedbackNotification handleClose={closeNotification} />
      )}
    </>
  )
}

export default PromosAfterItemUpload
