'use client'

import { useIntl } from 'react-intl'

import { Text, Dialog, Button, Navigation, Cell, Card, Divider } from '@vinted/web-ui'

import { ArrowLeft24 } from '@vinted/monochrome-icons'

import { FEATURED_COLLECTION_URL } from 'constants/routes'

import { ClickableElement } from 'constants/tracking/clickable-elements'

import { Screen } from 'constants/tracking/screens'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import ScrollableArea from 'components/ScrollableArea'

import { formatCurrencyAmount } from 'libs/utils/formatString'
import { navigateToPage } from 'libs/utils/window'
import { clickEvent } from 'libs/common/event-tracker/events'

import { CollectionsPlanPricingModel } from 'types/models'
import SeparatedList from 'components/SeparatedList'

type Props = {
  isShown: boolean
  plansPricing: Array<CollectionsPlanPricingModel>
  onBack: () => void
}

const MultipleCollectionsPlansModal = ({ isShown, plansPricing, onBack }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('featured_collection.multiple.plans_modal')
  const { locale } = useIntl()

  function handleCreateCollectionsClick() {
    track(
      clickEvent({ screen: Screen.FeaturedCollection, target: ClickableElement.CreateCollection }),
    )

    navigateToPage(FEATURED_COLLECTION_URL)
  }

  function renderNavigation() {
    return (
      <Navigation
        left={
          <Button
            iconName={ArrowLeft24}
            theme="amplified"
            styling={Button.Styling.Flat}
            testId="multiple-collections-plans-back-button"
            onClick={onBack}
            aria={{
              'aria-label': translate('a11y.actions.back'),
            }}
          />
        }
        body={
          <Text
            type={Text.Type.Title}
            width={Text.Width.Parent}
            alignment={Text.Alignment.Center}
            text={translate('title')}
          />
        }
      />
    )
  }

  function renderContent() {
    return (
      <>
        <Cell>
          <Text text={translate('body')} />
        </Cell>
        <div className="u-ui-margin-horizontal-large u-ui-margin-bottom-large">
          <Card>
            <div className="u-overflow-hidden">
              <SeparatedList separator={<Divider />}>
                {plansPricing.map(plan => (
                  <Cell
                    title={translate(
                      'plan_option.title',
                      { count: plan.maxActiveCount },
                      { count: plan.maxActiveCount },
                    )}
                    body={translate(
                      'duration_day_count',
                      { count: plan.effectiveDays },
                      { count: plan.effectiveDays },
                    )}
                    suffix={formatCurrencyAmount(plan.totalPrice, locale)}
                    key={plan.id}
                  />
                ))}
              </SeparatedList>
            </div>
          </Card>
        </div>
      </>
    )
  }

  return (
    <Dialog show={isShown} hasScrollableContent testId="multiple-collections-plans-modal">
      <div className="u-fill-width">
        {renderNavigation()}
        <Divider />
        <ScrollableArea>{renderContent()}</ScrollableArea>
        <Cell>
          <div className="u-flexbox u-justify-content-center u-ui-margin-bottom-regular">
            <Text text={translate('note')} type={Text.Type.Caption} />
          </div>
          <Button
            text={translate('actions.create')}
            styling={Button.Styling.Filled}
            onClick={handleCreateCollectionsClick}
            testId="multiple-collections-plans-create-button"
          />
        </Cell>
      </div>
    </Dialog>
  )
}

export default MultipleCollectionsPlansModal
